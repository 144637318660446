import { useEffect, useRef, useState } from "react";
import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk";
import { loadTossPayments } from "@tosspayments/payment-sdk";

import { nanoid } from "nanoid";

import { useQuery } from "@tanstack/react-query";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";
import { jwtDecode } from "jwt-decode";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "api/axios";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import PaymentIcon from "@mui/icons-material/Payment";
import MKSocialButton from "components/MKSocialButton";
import { Checkbox } from "@mui/material";
//toss
import "../payment/payment.css";

const selector = "#payment-widget";

// TODO: clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
// TODO: customerKey는 구매자와 1:1 관계로 무작위한 고유값을 생성하세요.
// @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
const customerKey = nanoid();
// const base_link = "http://localhost:3000";
const base_link = "https://iacat2024.com";

export default function CheckoutPage() {
  const [clientKey, setClientKey] = useState();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get("/payment/getResources");
      const decoded = jwtDecode(response.data.paymentToken);
      setClientKey(decoded.clientKey);
      return { clientKey: decoded.clientKey, customerKey: ANONYMOUS };
    } catch (err) {
      // Handle errors if needed
      console.error("Error fetching tokens:", err);
      throw err; // Rethrow the error to indicate failure
    }
  };
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [price, setPrice] = useState(0);
  const [socialEventSelected, setSocialEventSelected] = useState(null);
  const [workshopSelected, setWorkshopSelected] = useState(null);
  const [enrolled, setEnrolled] = useState([]);

  const handleEventSelect = (event) => {
    const { value, checked } = event.target;

    // Find the corresponding event data to get the price
    const eventData = data.rows.find((row) => row.checkboxValue === value);

    const isSocialEvent = value.startsWith("Social");
    const isWorkshop = value.startsWith("Workshops");

    if (checked) {
      if (
        isSocialEvent &&
        socialEventSelected &&
        socialEventSelected !== value
      ) {
        // Prevent selection of another social event
        alert("You can only select one social event at a time.");
        return;
      }

      if (
        enrolled.some((item) =>
          item.trim().toLowerCase().startsWith("social")
        ) &&
        isSocialEvent
      ) {
        alert("You can only select one social event at a time.");
        return;
      }

      if (isWorkshop && workshopSelected && workshopSelected !== value) {
        // Prevent selection of another workshop
        alert("You can only select one workshop at a time.");
        return;
      }

      if (
        enrolled.some((item) =>
          item.trim().toLowerCase().startsWith("workshops")
        ) &&
        isWorkshop
      ) {
        alert("You can only select one workshop at a time.");
        return;
      }

      setSelectedEvents([...selectedEvents, value]);
      setPrice(price + eventData.price * 1400);
      if (isSocialEvent) {
        setSocialEventSelected(value);
      }
      if (isWorkshop) {
        setWorkshopSelected(value);
      }
    } else {
      setSelectedEvents(selectedEvents.filter((event) => event !== value));
      setPrice(price - eventData.price * 1400);
      if (isSocialEvent) {
        setSocialEventSelected(null);
      }
      if (isWorkshop) {
        setWorkshopSelected(null);
      }
    }
  };
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const [userInfo, setUserInfo] = useState(
    storedToken ? jwtDecode(storedToken) : null
  );
  const [fname, setFname] = useState(userInfo ? userInfo.fname : null);
  const [lname, setLname] = useState(userInfo ? userInfo.lname : null);
  const [email] = useState(userInfo ? userInfo.email : null);
  const [role, setRole] = useState(userInfo ? userInfo.role : null);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isMissingField, setIsMissingField] = useState(false);

  const [paid, setPaid] = useState(userInfo ? userInfo.paid : null);
  const getUserData = async () => {
    setIsUpdating(true);
    if (email === null) {
      setIsUpdating(false);
      setEnrolled([]);
    } else if (email === "" || fname === "" || lname === "") {
      setIsMissingField(true);
      setIsUpdating(false);
    } else {
      const response = await axios.post(
        "/user/getUserEnrolled",
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      );
      if (response.data.data) {
        setEnrolled(response.data.data.split(",").map((item) => item.trim()));
      }
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    getUserData();
    getEnrolledData();
  }, []);

  const refreshUserInfo = async () => {
    setIsUpdating(true);
    if (email === "" || fname === "" || lname === "") {
      setIsMissingField(true);
      setIsUpdating(false);
    } else {
      const response = await axios.post(
        "/user/getUserInfo",
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      );
      localStorage.setItem("ACCESS_TOKEN", response.data.access_token);
      localStorage.setItem("EXPIRES_IN", response.data.expires_in.toString());
      localStorage.setItem("STATUS", "true");
      let decodedUserInfo = jwtDecode(response.data.access_token);
      setUserInfo(decodedUserInfo);
      setFname(decodedUserInfo.fname);
      setLname(decodedUserInfo.lname);
      setRole(decodedUserInfo.role);
      setPaid(decodedUserInfo.paid);
      setIsUpdating(false);
    }
  };

  const [enrolledData, setEnrolledData] = useState();
  const getEnrolledData = async () => {
    const response = await axios.get("/user/getEventEnrolledCount", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    });
    setEnrolledData(response.data.data);
  };

  const checkoutOnlyWelcome = async () => {
    const response = await axios.post(
      "/user/enrollInOnlyWelcomeReception",
      {
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }
    );
    if (response.status == 200) {
      window.location.reload();
    }
  };

  const data = {
    rows: [
      {
        key: "Workshop#1",
        title:
          "Workshop #1<br/>Introduction to AI-based Automated Item Generation and Scoring in Adaptive Testing",
        price: role === "student" ? 50 : 100,
        checkboxValue: "Workshops1",
        link: "/home#Workshops1",
        date: "Sept. 24 (Tuesday)",
      },
      {
        key: "Workshop#2",
        title: "Workshop #2<br/>Introduction to IRT and CAT",
        price: role === "student" ? 50 : 100,
        checkboxValue: "Workshops2",
        link: "/home#Workshops2",
        date: "Sept. 24 (Tuesday)",
      },
      {
        key: "Workshop#3",
        title: "Workshop #3<br/>The Shadow-Test Approach to Adaptive Testing",
        price: role === "student" ? 50 : 100,
        checkboxValue: "Workshops3",
        link: "/home#Workshops3",
        date: "Sept. 24 (Tuesday)",
      },
      {
        key: "Welcome",
        title: "Welcome reception",
        price: 0,
        checkboxValue: "WelcomeReception",
        link: "",
        date: "Sept. 24 (Tuesday)",
      },
      {
        key: "Banquet",
        title: "Banquet <br />1 Dinner at Yonsei University",
        price: 50,
        checkboxValue: "Banquet",
        link: "",
        date: "Sept. 25 (Wednesday)",
      },
      {
        key: "Social-1",
        title:
          "Socail event 1 <br />N Seoul Tower-Cable Car, Myeong-dong, Itaewon",
        price: 100,
        checkboxValue: "Social1",
        link: "/events/socialEvent1",
        date: "Sept. 26 (Thursday)",
      },

      {
        key: "Social-2",
        title:
          "Socail event 2 <br />Gyeongbokgung Palace, Hanbok Experience, Seoul Sky",
        price: 100,
        checkboxValue: "Social2",
        link: "/events/socialEvent2",
        date: "Sept. 26 (Thursday)",
      },
      {
        key: "Social-3",
        title:
          "Socail event 3 <br />Yeouido Han River Park, Han River Cruise, 64 Art Observator, Aqua Planet 63",
        price: 100,
        checkboxValue: "Social3",
        link: "/events/socialEvent3",
        date: "Sept. 26 (Thursday)",
      },
    ],
  };
  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid item xs={12} justifyContent="center" mx="auto">
                <Grid justifyContent="center" py={2}>
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h2">Check out!</MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="body1">
                        Please select all the events you want to register for!
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="body1">
                        <strong>
                          ** This will be a one time process so please select
                          all the events you wish to attend. **
                        </strong>
                      </MKTypography>
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        {isUpdating ? (
                          <div className="flex justify-center items-center h-40">
                            <div
                              className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">{"Loading"}...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            Membership Status{" "}
                            {paid ? (
                              <MKBadge
                                badgeContent="Registered"
                                size="xs"
                                container
                              />
                            ) : (
                              <>
                                {loggedin ? (
                                  <>
                                    <MKBadge
                                      badgeContent="Not Registered"
                                      size="xs"
                                      container
                                      color="error"
                                    />{" "}
                                    <MKButton
                                      variant="gradient"
                                      color="info"
                                      iconOnly
                                      size="small"
                                      onClick={refreshUserInfo}
                                    >
                                      <Icon sx={{ fontWeight: "bold" }}>
                                        replay_icon
                                      </Icon>
                                    </MKButton>
                                    <br />
                                    <small>
                                      *Amount will be collected in KRW as an
                                      international payment. Therefore prices in
                                      USD will vary based on the currency
                                      exchange rate.
                                    </small>
                                    <br />
                                  </>
                                ) : (
                                  <div>
                                    Please log in first to see membership status
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="body2">
                        Social Events are subject to number of participants. If
                        the number of participants for a social event is lower
                        than 20 people, the event may be cancelled resulting in
                        a full refund. Therefore, reach out to fellow
                        participants to go on a breathtaking tour of Seoul
                        together!
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="body2">
                        * Each Social Events have a limit of 50 people, secure
                        your seat ASAP
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="body2">
                        * For social events you may only select 1 out of the 3
                        options.
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="body2">
                        * For workshop events you may only select 1 out of the 3
                        options.
                      </MKTypography>
                    </MKBox>

                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      {" "}
                      {enrolled.length > 0 ? (
                        <div>
                          You are current enrolled in the following events.
                          <br />
                          <MKTypography variant="body2">
                            * If you wish to change your enrolled sessions,
                            please contact{" "}
                            <a
                              style={{ color: "blue" }}
                              href="mailto:support@iacat2024.com"
                            >
                              support@iacat2024.com
                            </a>{" "}
                            for a partial / full refund. After the refund has
                            been issued, please register for the events again.
                          </MKTypography>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead style={{ display: "contents" }}>
                                <TableRow
                                  style={{ display: "table-header-group" }}
                                >
                                  <TableCell>Event Type</TableCell>
                                  <TableCell>Event Link</TableCell>
                                  <TableCell>Date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {enrolled.map((enrolled) => {
                                  const rowData = data.rows.find(
                                    (row) => row.checkboxValue === enrolled
                                  );
                                  return (
                                    <TableRow
                                      key={rowData.key}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: rowData.title,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.link ? (
                                          <a
                                            style={{ color: "blue" }}
                                            target="_blank"
                                            href={rowData.link}
                                          >
                                            {base_link + rowData.link}
                                          </a>
                                        ) : (
                                          "N/A"
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.date}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <MKTypography variant="body1" mb={2} mt={2}>
                            All Events
                          </MKTypography>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead style={{ display: "contents" }}>
                                <TableRow
                                  style={{ display: "table-header-group" }}
                                >
                                  <TableCell>Event Type</TableCell>
                                  <TableCell>Event Link</TableCell>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Participant Count</TableCell>
                                  <TableCell>Price</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data.rows.map((row) => (
                                  <TableRow
                                    key={row.key}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: row.title,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.link ? (
                                        <a
                                          style={{ color: "blue" }}
                                          target="_blank"
                                          href={row.link}
                                        >
                                          {base_link + row.link}
                                        </a>
                                      ) : (
                                        "N/A"
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.date}
                                    </TableCell>
                                    <TableCell align="left">
                                      {(() => {
                                        const count =
                                          enrolledData?.find(
                                            (counts) =>
                                              counts.item === row.checkboxValue
                                          )?.count || 0;

                                        if (
                                          row.checkboxValue.includes("Workshop")
                                        ) {
                                          return count < 60
                                            ? "Available"
                                            : "Capacity Reached";
                                        } else if (
                                          row.checkboxValue.includes("Social")
                                        ) {
                                          return count < 45
                                            ? "Available"
                                            : "Capacity Reached";
                                        } else {
                                          return "Available";
                                        }
                                      })()}
                                    </TableCell>

                                    <TableCell align="left">
                                      ~${row.price}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      ) : (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead style={{ display: "contents" }}>
                              <TableRow
                                style={{ display: "table-header-group" }}
                              >
                                <TableCell>Event Type</TableCell>
                                <TableCell>Event Link</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Availability</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Select</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.rows.map((row) => (
                                <TableRow
                                  key={row.key}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: row.title,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.link ? (
                                      <a
                                        style={{ color: "blue" }}
                                        target="_blank"
                                        href={row.link}
                                      >
                                        {base_link + row.link}
                                      </a>
                                    ) : (
                                      "N/A"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">{row.date}</TableCell>
                                  <TableCell align="left">
                                    {(() => {
                                      const count =
                                        enrolledData?.find(
                                          (counts) =>
                                            counts.item === row.checkboxValue
                                        )?.count || 0;

                                      if (
                                        row.checkboxValue.includes("Workshop")
                                      ) {
                                        return count < 60
                                          ? "Available"
                                          : "Capacity Reached";
                                      } else if (
                                        row.checkboxValue.includes("Social")
                                      ) {
                                        return count < 45
                                          ? "Available"
                                          : "Capacity Reached";
                                      } else {
                                        return "Available";
                                      }
                                    })()}
                                  </TableCell>

                                  <TableCell align="left">
                                    ~${row.price}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox
                                      value={row.checkboxValue}
                                      checked={
                                        selectedEvents.includes(
                                          row.checkboxValue
                                        ) ||
                                        enrolled.includes(row.checkboxValue)
                                      }
                                      disabled={enrolled.includes(
                                        row.checkboxValue
                                      )}
                                      onChange={handleEventSelect}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      <br />
                    </MKBox>

                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      {selectedEvents.length > 0 && (
                        <>Total Price (Won): {price}</>
                      )}
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    ></MKBox>
                    {loggedin &&
                      paid &&
                      selectedEvents.length === 1 &&
                      selectedEvents[0] === "WelcomeReception" && (
                        <MKSocialButton onClick={checkoutOnlyWelcome}>
                          <PaymentIcon />
                          &nbsp;Checkout Welcome Reception
                        </MKSocialButton>
                      )}
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                      style={{
                        display:
                          selectedEvents.length > 0 &&
                          !(
                            selectedEvents.length === 1 &&
                            selectedEvents[0] === "WelcomeReception"
                          )
                            ? "inline-block"
                            : "none",
                        width: "100%",
                      }}
                    >
                      {loggedin && paid ? (
                        <MKSocialButton
                          onClick={async () => {
                            // TODO: 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                            // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                            try {
                              loadTossPayments(clientKey).then(
                                (tossPayments) => {
                                  // ------ 결제창 띄우기 ------
                                  tossPayments
                                    .requestPayment("카드", {
                                      amount: price, // 결제 금액
                                      orderId: nanoid(), // 주문번호
                                      orderName: selectedEvents.join(", "),
                                      customerName: fname + " " + lname,
                                      customerEmail: email,
                                      // useInternationalCardOnly: true,
                                      successUrl: `${window.location.origin}/user/register/tosspaymentconfirmevents`,
                                      failUrl: `${window.location.origin}/home`, // 결제 실패 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
                                    })
                                    .catch(function (error) {
                                      if (error.code === "USER_CANCEL") {
                                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                                      } else if (
                                        error.code === "INVALID_CARD_COMPANY"
                                      ) {
                                        // 유효하지 않은 카드 코드에 대한 에러 처리
                                      }
                                    });
                                }
                              );
                            } catch (error) {
                              // 에러 처리하기
                              console.error(error);
                            }
                          }}
                        >
                          <PaymentIcon />
                          &nbsp;Checkout
                        </MKSocialButton>
                      ) : (
                        <div>
                          Please log in & register to IACAT2024 first to
                          register for events. <br />
                          You may complete registration{" "}
                          <a
                            style={{ color: "blue" }}
                            href="/user/registration"
                          >
                            here
                          </a>
                        </div>
                      )}
                    </MKBox>
                  </Grid>
                  <Grid></Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        {/* <Contact /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

function usePaymentWidget(clientKey, customerKey) {
  return useQuery({
    queryKey: ["payment-widget", clientKey, customerKey],
    queryFn: () => {
      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      return loadPaymentWidget(clientKey, customerKey);
    },
  });
}
