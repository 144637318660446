/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

// Routes
import routes from "routes";
import TravelHeader from "./sections/TravelHeader";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";

function Travel() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <TravelHeader />
        </Card>
        <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </MKBox>
    </>
  );
}

export default Travel;
