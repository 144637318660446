import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../payment/payment.css";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Container from "@mui/material/Container";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";
import { jwtDecode } from "jwt-decode";

import {Grid} from "@mui/material";
import axios from "api/axios";

export function ConfirmSponsorPage() {
  //confirm toss
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  const email = userInfo.email;
  const loggedin = localStorage.getItem("STATUS");
  const [isloading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
      email: email,
    };

    if (
      !searchParams.get("orderId")||
      !searchParams.get("amount")||
      !searchParams.get("paymentKey")||
      !email === "" || 
      loggedin !== "true"
    ) {
      navigate("/user/sponsor/checkout");
    } else {
      async function confirm() {
        try {
          await axios.post("/payment/confirmpaymentSponsor", requestData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error confirming payment:', error);
          // Handle the error if needed
          setIsLoading(false);
          if (error.data.code === "INVALID_CARD_NUMBER") {
            setErrorMsg("The Card Number entered is not correct")
          } else {
            setErrorMsg("Something Went Wrong")
          }
        }
      }
      confirm();
    }});
  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={0}>
                    </Grid>
            </Grid>
            {isloading ? (<>Loading...</>) : (
                <>
                Thank you for your payment. We have recieved your sponsorship
                registration. A member on our team will reach out to you regarding
                your sponsorship. If you do not receive a email within 3-5
                buisness days please contact{" "}
                <a style={{ color: "blue" }} href="mailto:support@iacat2024.com">
                  support@iacat2024.com
                </a></>
            )}
            </Container>
          </MKBox>
        </Card>
      </MKBox>
    </>
  );
}
