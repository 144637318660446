/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";
function CallForPapers() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={6}>
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h2">
                        Improving Assessment with Adaptivity and Artificial
                        Intelligence
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h6">
                        Deadline: <u>July 6, 2024</u>
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="caption">
                        * Please note: The deadline for proposals have been extended to July 6, 2024
                      </MKTypography>
                    </MKBox>
                    <MKTypography variant="h3" mt={5}>
                      Session Types
                    </MKTypography>
                    <MKTypography variant="h6">
                      There are two types of sessions: Individual Presentations
                      and Coordinated/Symposia Presentations.
                    </MKTypography>
                    <MKTypography variant="h5" mt={5}>
                      <i>Individual Presentations</i>
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      These sessions can be a single presenter or group of
                      presenters, discussing the results of a research study or
                      actual assessment. This is a traditional lecture-style
                      presentation that will be 20-30 minutes as part of a block
                      of presentations.
                      <br/> <br/>
                      <u>Proposals for standard sessions must include the following components :</u>
                      <ol>
                        <li>• Proposal title</li>
                        <li>• Author(s) name(s)</li>
                        <li>• Abstract (500 words or less)</li>
                        <li>• Which IACAT 2024 strand the presentation belongs to</li>
                        <li>• At least three keywords</li>
                      </ol>
                    </MKTypography>
                    <MKTypography variant="h5" mt={5}>
                      <i>Coordinated/Symposia Presentations</i>
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      These sessions are a group of 3-4 related presentations,
                      submitted together as a block, or alternatively can be a
                      symposium with multiple speakers on one topic.  These will
                      be approximately 60 minutes in length. We encourage
                      formats such as panel discussions or debates.
                      <br/> <br/>
                      <u>Symposia Proposal Guidelines: </u>
                      <ol>
                        <li>• Proposal title</li>
                        <li>• Organizer(s) name(s)</li>
                        <li>• Abstract (500 words or less)</li>
                        <li>• Which IACAT 2024 strand the presentation belongs to</li>
                        <li>• At least three keywords</li>
                      </ol>
                    </MKTypography>
                    <MKTypography variant="h3" mt={5}>
                      Submission Guidelines
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      {/* To submit a proposal, go to this{" "}
                      <a href="http://iacat.org/submit-proposal-iacat-2019-webform">
                        submission
                      </a>{" "}
                      page and follow the prompts. */}
                      {/* <u>
                        <a href="/pages/callforpapers/submissions">
                          Submission link{" "}
                        </a>
                        !
                      </u> */}
                      <u>
                        Submission Window has offically ended. If you have any questions/concerns please email 
                      </u>                        <a
                              style={{ color: "blue" }}
                              href="mailto:support@iacat2024.com"
                            >
                              support@iacat2024.com
                            </a>{" "}
                      <br />
                      <small>Decision letters will be sent out at July 1, 2024 for proposals submitted before June 15, 2024.</small>
                      <br />
                      <small>Decision letters will be sent out at July 15, 2024 for proposals submitted before July 6, 2024.</small>
                      <br />
                      <br />
                      Proposals should be limited to 500 words for Individual
                      Presentations and 2,500 words for Coordinated/Symposia
                      Presentations (include all speakers in one submission).
                      <br /> <br />
                      Submissions will be evaluated on: quality of the
                      work/research, alignment with the theme of the conference,
                      innovation or contribution to the literature, and clarity.
                      All presentations are planned be videorecorded and will
                      later be made available on the IACAT conference website.
                      For examples of the most recent conference, presentation
                      videos are available on the IACAT Website.
                    </MKTypography>
                    <MKTypography variant="h3" mt={5}>
                      Recommended Topics
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      <div style={{ paddingLeft: "50px" }}>
                        <ol>
                          <li>Applications of CAT: Education and Language</li>
                          <li>
                            Applications of CAT: Certification and Workforce
                          </li>
                          <li>
                            Applications of CAT in personality and attitude
                            measurement, and in patient-reported outcomes
                          </li>
                          <li>
                            Item generation, item banking, and form assembly
                          </li>
                          <li>Item selection and termination criteria</li>
                          <li>IRT models (including multidimensional)</li>
                          <li>Cognitive diagnostic models</li>
                          <li>Multistage testing (MST)</li>
                          <li>
                            Other AI topics (e.g., automated essay scoring)
                          </li>
                          <li>Adaptive learning</li>
                        </ol>
                      </div>
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        {/* <Contact /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

export default CallForPapers;
