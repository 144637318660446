/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Card from "@mui/material/Card";
import routes from "routes";

import { useState } from "react";
import { jwtDecode } from "jwt-decode";

import hangang from "../events/eventPics/hangang.jpeg";
import cruise from "../events/eventPics/hanrivercruise.jpeg";
import yooksam from "../events/eventPics/63.jpeg";
import aqua from "../events/eventPics/aqua.webp"
import Carousel from "react-material-ui-carousel";
import { Paper as CarouselPaper } from "@mui/material";

function SocialEvent3() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  const carouselItems = [
    {
      src: hangang,
      alt: "hangang",
    },
    {
      src: cruise,
      alt: "cruise",
    },
    {
      src: yooksam,
      alt: "yooksam",
    },
    {
      src: aqua,
      alt: "aqua"   
    }
  ];
  const eventDetails = [
    {
      time: "2:00 PM",
      activity: "Meet @ Yonsei's Main Gate",
      description: "Start!",
    },
    {
      time: "2:30 PM - 3:30 PM",
      activity: "Yeouido Han River Park",
      description: "Enjoy a relaxing stroll through Yeouido Han River Park, a beautiful riverside park in Seoul.",
    },
    {
      time: "4:00 PM - 5:00 PM",
      activity: "Han River Cruise",
      description: "Experience the beauty of Seoul from the water on a Han River Cruise.",
    },
    {
      time: "5:30 PM - 7:00 PM",
      activity: "63 Art Observator & Aqua Planet 63",
      description: "Explore the 63 Art Observator and the underwater wonders of Aqua Planet 63.",
    },
    {
      time: "8:00 PM",
      activity: "Return to Yonsei University",
      description: "Head back to Yonsei and relax after a day full of exciting experiences.",
    },
  ];

  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in",
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h5">
                        Social Event #3: Yeouido Han River Park, Han River Cruise, 63 Art Observator & Aqua Planet 63
                      </MKTypography>
                    </MKBox>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mb={3}
                    >
                      Join us for an unforgettable tour of some of Seoul's most iconic attractions! Our third social event will take you through the scenic Yeouido Han River Park, along a beautiful Han River Cruise, and into the artistic and aquatic wonders of the 63 Art Observator and Aqua Planet 63. This is your chance to immerse yourself in the rich culture and lively atmosphere of Seoul while making lasting memories with fellow participants.
                    </MKTypography>
                    <Carousel
                      sx={{
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        overflow: "hidden",
                        mb: 3,
                      }}
                    >
                      {carouselItems.map((item, index) => (
                        <CarouselPaper key={index}>
                          <img
                            src={item.src}
                            alt={item.alt}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </CarouselPaper>
                      ))}
                    </Carousel>
                    <MKTypography
                      variant="h6"
                      fontWeight="medium"
                      color="text"
                      mb={1}
                    >
                      Event Schedule
                    </MKTypography>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead style={{ display: "contents" }}>
                            <TableRow style={{ display: "table-header-group" }}>
                            <TableCell>Time</TableCell>
                            <TableCell>Activity</TableCell>
                            <TableCell>Description</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {eventDetails.map((event, index) => (
                            <TableRow key={index}>
                              <TableCell>{event.time}</TableCell>
                              <TableCell>{event.activity}</TableCell>
                              <TableCell>{event.description}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default SocialEvent3;
