/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "api/axios";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import PaymentIcon from "@mui/icons-material/Payment";
import MKSocialButton from "components/MKSocialButton";

//toss
import { useEffect, useRef } from "react";
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import "./payment/payment.css";

import { loadTossPayments } from "@tosspayments/payment-sdk";

import viewoptions from "assets/images/payment/viewoptions.png";
import cardSelection from "assets/images/payment/cardselection.png";
import payment from "assets/images/payment/payment.png";
import { Box } from "@mui/material";

function RegistrationBasic() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const [userInfo, setUserInfo] = useState(
    storedToken ? jwtDecode(storedToken) : null
  );
  const [fname, setFname] = useState(userInfo ? userInfo.fname : null);
  const [lname, setLname] = useState(userInfo ? userInfo.lname : null);
  const [email] = useState(userInfo ? userInfo.email : null);
  const [role, setRole] = useState(userInfo ? userInfo.role : null);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isMissingField, setIsMissingField] = useState(false);

  const [paid, setPaid] = useState(userInfo ? userInfo.paid : null);
  const [showPaymentWidget, setShowPaymentWidget] = useState(false);

  //Toss
  const [price, setPrice] = useState(role === "regular" ? 550_000 : 400_000);
  useEffect(() => {
    if (["jyoti.pandey@ibps.in", "vchilana@ibps.in", "mkmayankkumar1@gmail.com", "neil.rice.ex@gmail.com", "hyunsukr@gmail.com"].includes(email)) {
      setPrice(400_000);
    } else if (["djweiss@umn.edu", "snodg031@umn.edu", "tai00006@umn.edu", "gree2903@umn.edu", "chapm564@umn.edu", "delga220@umn.edu", "laura.ortega@ceneval.edu.mx"].includes(email)) {
      setPrice(200_000);
    } else {
      setPrice(role === "regular" ? 550_000 : 400_000);
    }
  }, [email, role]);
  
  const [clientKey, setClientKey] = useState();
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get("/payment/getResources");
      const decoded = jwtDecode(response.data.paymentToken);
      setClientKey(decoded.clientKey);
      return { clientKey: decoded.clientKey, customerKey: ANONYMOUS };
    } catch (err) {
      // Handle errors if needed
      console.error("Error fetching tokens:", err);
      throw err; // Rethrow the error to indicate failure
    }
  };

  const refreshUserInfo = async () => {
    setIsUpdating(true);
    if (email === "" || fname === "" || lname === "") {
      setIsMissingField(true);
      setIsUpdating(false);
    } else {
      const response = await axios.post(
        "/user/getUserInfo",
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      );
      localStorage.setItem("ACCESS_TOKEN", response.data.access_token);
      localStorage.setItem("EXPIRES_IN", response.data.expires_in.toString());
      localStorage.setItem("STATUS", "true");
      let decodedUserInfo = jwtDecode(response.data.access_token);
      setUserInfo(decodedUserInfo);
      setFname(decodedUserInfo.fname);
      setLname(decodedUserInfo.lname);
      setRole(decodedUserInfo.role);
      setPaid(decodedUserInfo.paid);
      setIsUpdating(false);
    }
  };

  function createData(type, rate, rate2) {
    return { type, rate, rate2 };
  }

  const rows = [
    createData("Regular", "~$300", "~$400"),
    createData("Graduate *", "~$200", "~$300"),
  ];

  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={2}>
                  <Grid item xs={12} md={10} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h2">
                        Registration Guidelines
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography>
                        You can purchase tickets for the conference using the
                        PAY NOW button link below. 
                        After registering for the conference, 
                        register for the many events that the IACAT2024 Committee has prepared for registered attendees 
                        <a style={{'color': 'blue'}} href="/user/register/events/checkout"> here.</a>
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      {" "}
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead style={{ display: "contents" }}>
                            <TableRow style={{ display: "table-header-group" }}>
                              <TableCell>Attendee Type</TableCell>

                              <TableCell>
                                <s>Early Admission Rate <br /> March 15, 2024 ~
                                July 15, 2024</s>{" "}
                              </TableCell>
                              <TableCell>
                                Regular Admission Rate <br /> July 16, 2024 ~
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow
                                key={row.type}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.type}
                                </TableCell>
                                <TableCell align="left"><s>{row.rate}</s></TableCell>
                                <TableCell align="left">{row.rate2}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="header">
                        * Extension: Early Admission Final Date Extended!
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="caption">
                        * Please note: Eligible for the Graduate rates are
                        Pre-Docs and students who have not yet graduated with a
                        Master’s degree, but are enrolled at a higher education
                        institution. Proof of membership and /or status has to
                        be sent to{" "}
                        <u>
                          <a href="mailto:7castle42@gmail.com">
                            (7castle42@gmail.com)
                          </a>
                        </u>{" "}
                        prior to the registration process. To verify your
                        Pre-Doc status, a letter from your supervisor is
                        sufficient. To verify your student status, please
                        provide a copy of a valid student ID.
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MKTypography>
                        Please find the supplemental payment instructions below.
                        The payment window will be visible after clicking
                        paynow!
                      </MKTypography>
                    </MKBox>
                    <Grid justifyContent="center" container spacing={2} mt={2}>
                      <Grid item xs={12} md={4} shadow="xl">
                        <Box
                          sx={{
                            boxShadow:
                              "0 8px 12px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15)", // Thicker shadow
                            // Adjust the values for your desired shadow
                            borderRadius: "8px", // Optional: Add border radius for a rounded corner effect
                            overflow: "hidden", // Optional: Hide any overflowing content
                          }}
                        >
                          <img
                            src={viewoptions}
                            alt="View Options"
                            style={{ width: "100%" }}
                          />
                        </Box>
                        <MKTypography variant="body2" align="center">
                          View Options
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box
                          sx={{
                            boxShadow:
                              "0 8px 12px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15)", // Thicker shadow
                            // Adjust the values for your desired shadow
                            borderRadius: "8px", // Optional: Add border radius for a rounded corner effect
                            overflow: "hidden", // Optional: Hide any overflowing content
                          }}
                        >
                          <img
                            src={cardSelection}
                            alt="Card Selection"
                            style={{ width: "100%" }}
                          />
                        </Box>
                        <MKTypography variant="body2" align="center">
                          Card Selection
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box
                          sx={{
                            boxShadow:
                              "0 8px 12px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15)", // Thicker shadow
                            // Adjust the values for your desired shadow
                            borderRadius: "8px", // Optional: Add border radius for a rounded corner effect
                            overflow: "hidden", // Optional: Hide any overflowing content
                          }}
                        >
                          <img
                            src={payment}
                            alt="Payment"
                            style={{ width: "100%" }}
                          />
                        </Box>
                        <MKTypography variant="body2" align="center">
                          Payment
                        </MKTypography>
                      </Grid>
                    </Grid>
                    <MKBox
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      {loggedin ? (
                        <>
                          <MKTypography fontWeight="light" variant="h6">
                            {isUpdating ? (
                              <div className="flex justify-center items-center h-40">
                                <div
                                  className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                                  role="status"
                                  aria-label="loading"
                                >
                                  <span className="sr-only">
                                    {"Loading"}...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                Membership Status{" "}
                                {paid ? (
                                  <MKBadge
                                    badgeContent="Registered"
                                    size="xs"
                                    container
                                  />
                                ) : (
                                  <>
                                    <MKBadge
                                      badgeContent="Not Registered"
                                      size="xs"
                                      container
                                      color="error"
                                    />{" "}
                                    <MKButton
                                      variant="gradient"
                                      color="info"
                                      iconOnly
                                      size="small"
                                      onClick={refreshUserInfo}
                                    >
                                      <Icon sx={{ fontWeight: "bold" }}>
                                        replay_icon
                                      </Icon>
                                    </MKButton>
                                    <br />
                                    <small>
                                      *Amount will be collected in KRW as an
                                      international payment. Therefore prices in
                                      USD will vary based on the currency
                                      exchange rate.
                                    </small>
                                    <br />
                                  </>
                                )}
                              </>
                            )}
                          </MKTypography>
                          <MKSocialButton
                        onClick={async () => {
                          // TODO: 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                          // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                          try {
                            loadTossPayments(clientKey).then((tossPayments) => {
                              // ------ 결제창 띄우기 ------
                              tossPayments
                                .requestPayment("카드", {
                                  // 결제수단 파라미터
                                  // 결제 정보 파라미터
                                  // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
                                  // https://docs.tosspayments.com/reference/js-sdk
                                  amount: price, // 결제 금액
                                  orderId: nanoid(),
                                  orderName: "IACAT 2024 Registration",
                                  customerName: fname + " " + lname,
                                  customerEmail: email,
                                  successUrl: `${window.location.origin}/user/register/tosspaymentconfirm`,
                                  failUrl: `${window.location.origin}/home`,
                                  // useInternationalCardOnly: true, // 결제 실패 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
                                })
                                .catch(function (error) {
                                  if (error.code === "USER_CANCEL") {
                                    // 결제 고객이 결제창을 닫았을 때 에러 처리
                                  } else if (
                                    error.code === "INVALID_CARD_COMPANY"
                                  ) {
                                    // 유효하지 않은 카드 코드에 대한 에러 처리
                                  }
                                });
                            });
                          } catch (error) {
                            // 에러 처리하기
                            console.error(error);
                          }
                        }}
                        style={{
                          display: paid ? "none" : "inline-block",
                        }}
                      >
                        <PaymentIcon />
                        &nbsp;Pay now!
                      </MKSocialButton>
                        </>
                      ) : (
                        <div>Log in first to see your registration status / Pay for Registration</div>
                      )}
                      <div
                        id="payment-widget"
                        style={{
                          // display: showPaymentWidget ? "inline-block" : "none",
                          width: "100%",
                        }}
                      />
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h2">
                        Conference Registration Cancellation Policy:
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography>
                        Cancellations made prior to August 01, 2024 will result
                        in a 100% refund of the registration fees, and a
                        cancellation between August 01, 2024 and September 10,
                        2024 will result in a 50% refund of the registration
                        fees. There will no refund of any registration fees
                        after September 10, 2024. Refunds must be requested by
                        sending an e-mail to support@iacat2024.com.
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h2">
                        Conference Event Registration
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography>
                        After registering for the conference, register for the many events that the IACAT2024 Committee has prepared for registered attendees <a style={{'color': 'blue'}} href="/user/register/events/checkout">here.</a>
                      </MKTypography>
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        {/* <Contact /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

export default RegistrationBasic;
