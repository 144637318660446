/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";
import HotelInformation from "../sections/HotelInformation";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Card from "@mui/material/Card";
import routes from "routes";

import { useState } from "react";
import { jwtDecode } from "jwt-decode";

function HotelLanding() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: loggedin ? "/userinfo" : "/signin",
          label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
          color: "info",
        }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h5">
                        Hotel Accommodation
                      </MKTypography>
                    </MKBox>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      Special room rates have been arranged with a number of
                      hotels of different categories for the 2024 IACAT
                      Conference. And Yonsei University is about a 10-minute
                      drive from the hotel, and a shuttle bus is provided.
                      Please click on each card to view specifics to each hotel.
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
              <HotelInformation />
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default HotelLanding;
